var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newsletter",class:{
    [`mp--${_vm.mobilePadding}`]: Boolean(_vm.mobilePadding),
    [`mm--${_vm.mobileMargin}`]: Boolean(_vm.mobileMargin),
    [`dp--${_vm.desktopPadding}`]: Boolean(_vm.desktopPadding),
    [`dm--${_vm.desktopMargin}`]: Boolean(_vm.desktopMargin),
    [`di ${_vm.newsletterIndent}`]: Boolean(_vm.newsletterIndent),
  },style:([_vm.backgroundColorCss, _vm.foregroundColorCss])},[_c('div',{staticClass:"content grid dc--1 dg--xlarge"},[_c('div',{staticClass:"text-wrap"},[(_vm.headline)?_c('div',{staticClass:"headline",class:_vm.headlineSize,domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.headline.html))}}):[_c('div',{staticClass:"headline h--medium show-on-mobile"},[_vm._v("\n          "+_vm._s(_vm.$t('Newsletter_Headline'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"headline h--x-large di--4 show-on-desktop"},[_vm._v("\n          "+_vm._s(_vm.$t('Newsletter_Headline'))+"\n        ")])],_vm._v(" "),(_vm.text)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.text.html)}}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"input-text-underline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"newsletter__input-text",attrs:{"type":"email","placeholder":_vm.getPlaceholder},domProps:{"value":(_vm.email)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.subscribe.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_vm._v(" "),_c('button',{attrs:{"aria-label":_vm.getButtonLabel,"disabled":_vm.loading},on:{"click":_vm.subscribe}},[_c('ArrowLongIcon',{staticClass:"newsletter__input__icon"})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }